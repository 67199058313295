import { axiosInstance } from "../../Axios";

export const downloadProcurementReports =
  (ediBodyData, setLoader, alert) => async () => {
    try {
      setLoader(true);
      const res = await axiosInstance.post(
        `procurement/procurement_reports/`,
        ediBodyData,
        {
          responseType: "arraybuffer",
        }
      );
      if (res.data) {
        setLoader(false);
        downloadReport(res.data, ediBodyData.report);
      } else if (res.data.errorMsg) {
        setLoader(false);
        alert(res.data.errorMsg, {
          variant: "error",
        });
      }
    } catch (err) {
      alert(err, {
        variant: "error",
      });
    }
  };

export const downloadReport = (arrayBuffer, FileName) => {
  let blob = new Blob([arrayBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = FileName;
  link.click();
};

export const getProAllTools =
  (notify) =>
  async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    const {pg_no,set_on_page_data,category,sku_code,name,process,from_date,to_date} = await getState().Procurement.allTools
    dispatch({ type: "GET_ALL_TOOLS_TABLE_LOAD", payload: "TRUE" });

    axiosInstance
      .post("procurement/get_all_tool/", {
        location: location,
        site: site,
        pg_no,
        on_page_data:set_on_page_data,
        category,
        sku_code,
        name,
        from_date,
        to_date,
        process
      })
      .then((res) => {
        dispatch({ type: "GET_ALL_TOOLS_TABLE_LOAD", payload: "FALSE" });
        dispatch({ type: "GET_ALL_TOOLS", payload: res.data });
      })
      .catch((err) => {
        console.log("Dashboard Error !", err);
      });
  };



export const addTools = (val, notify) => async (dispatch, getState) => {
  const location = await getState().user.location;
  const site = await getState().user.site;

  axiosInstance
    .post("procurement/add_tool/", {
      ...val,
      location,
      site,
    })
    .then((res) => {
      notify("Tool Added Succesfully", { variant: "success" });
      dispatch(getProAllTools(notify));
    })
    .catch((err) => {
      notify("Tool Added Failed", { variant: "error" });
      console.log("Dashboard Error !", err);
    });
};

export const editTools = (val, notify) => async (dispatch) => {
  axiosInstance
    .get(`procurement/get_tool/${val.pk}/`)
    .then((res) => {
      dispatch({ type: "EDIT_TOOLS", payload: res.data });
    })
    .catch((err) => {
      console.log("Dashboard Error !", err);
    });
};

export const editToolsButton =
  (pk, val, notify) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;

    axiosInstance
      .put(`procurement/get_tool/${pk}/`, {
        ...val,
        location,
        site,
      })
      .then((res) => {
        dispatch({
          type: "EDIT_TOOLS",
          payload: {
            category: "",
            hsn_code: "",
            rate: "",
            name: "",
          },
        });
        notify("Tool updated ", { variant: "success" });
        dispatch(getProAllTools(notify));
      })
      .catch((err) => {
        notify("Tool updated Failed", { variant: "error" });
        console.log("Dashboard Error !", err);
      });
  };

export const editToolsEmpty = () => async (dispatch, getState) => {
  dispatch({
    type: "EDIT_TOOLS",
    payload: {},
  });
};

export const extractTools = (fileInput) => async (dispatch) => {
  dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { loading: true } });
  axiosInstance
    .post(`procurement/extract_tool_room_data/`, fileInput)
    .then((res) => {
      dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { loading: false } });
      dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { data: res.data } });
    })
    .catch((err) => {
      console.log("Dashboard Error !", err);
    });
};

export const extractToolData = (fileData, alert) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { loading: true } });
    const res = await axiosInstance.post(
      `/procurement/extract_tool_room_data/`,
      fileData
    );
    if (res.data.errorMsg) {
      alert(res.data.errorMsg, { variant: "error" });
    } else {
      dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { loading: false } });
      dispatch({ type: "GET_EXTRACT_TOOL_DATA", payload: { data: res.data } });
    }
  } catch (err) {
    alert(err, {
      variant: "error",
    });
  }
};

export const downloadEDI = (arrayBuffer, FileName, FileType) => {
  let blob = new Blob([arrayBuffer], {
    type: FileType,
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = FileName;
  link.click();
};

export const importToolData =
  (importArray, alert, history) => async (dispatch) => {
    try {
      const res = await axiosInstance.post(
        `/procurement/import_tool_room_data/`,
        importArray,
        { responseType: "arraybuffer" },
        history.push("/procurement/tools")
      );
      if (res.data) {
        downloadEDI(res.data, "ToolRoom_Approved");

        alert("Imported Successfully ", { variant: "success" });
      } else if (res.data.errorMsg) {
        alert(res.data.errorMsg, { variant: "error" });
      }
    } catch (err) {
      alert(err, { variant: "error" });
    }
  };

export const downloadToolRejectedData =
  (rejectArray, alert, history) => async (dispatch) => {
    try {
      const res = await axiosInstance.post(
        `/procurement/rejected_tools_file/`,
        rejectArray,
        { responseType: "arraybuffer" },
        history.push("/procurement/tools")
      );
      if (res.data) {
        downloadSample(res.data, "ToolRoom Rejected");
      } else if (res.data.errorMsg) {
        alert(res.data.errorMsg, { variant: "error" });
      }
    } catch (err) {
      alert(err, {
        variant: "error",
      });
    }
  };

export const deleteProAllTools = (val, notify) => async (dispatch) => {
  const data = val.map((d) => d.pk);
  try {
    axiosInstance
      .post(`procurement/delete_tool/`, data)
      .then((res) => {
        notify(res.data.successMsg, { variant: "success" });
        dispatch(getProAllTools());
      })
      .catch((err) => {
        notify("Failed to delete ,Items has dependencies", {
          variant: "error",
        });
        console.log("Dashboard Error !", err);
      });
  } catch (error) {
    notify(error.message, { variant: "error" });
  }
};
export const deleteSingleData = (val, notify) => async (dispatch) => {
  const data = val.pk;
  axiosInstance
    .post(`procurement/delete_tool/`, [data])
    .then((res) => {
      notify(res.data.successMsg, { variant: "success" });
      dispatch(getProAllTools());
    })
    .catch((err) => {
      notify("Failed to delete ,Item has dependencies", { variant: "error" });
      console.log("Dashboard Error !", err);
    });
};

export const downloadSample = (arrayBuffer, FileName) => {
  let blob = new Blob([arrayBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = FileName;
  link.click();
};

export const downloadToolSampleData = (alert) => async () => {
  try {
    const res = await axiosInstance.get(
      `/procurement/get_tool_room_sample_file/`,
      {
        responseType: "arraybuffer",
      }
    );
    if (res.data) {
      downloadSample(res.data, "ToolRoom_Sample_File");
      alert("Sample File Downloaded Succesfully", { variant: "success" });
    } else if (res.data.errorMsg) {
      alert(res.data.errorMsg, { variant: "error" });
    }
  } catch (err) {
    alert(err, {
      variant: "error",
    });
  }
};

export const editProAllToolsRequest = (val) => async (dispatch) => {
  try {
    dispatch({ type: "EDIT_REQUEST", payload: val });
  } catch (err) {
    console.log(err);
  }
};

export const editDeleteProAllToolsRequest = () => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_EDIT_REQUEST" });
  } catch (err) {
    console.log(err);
  }
};

export const getRequestAllTools = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_ALL_TOOLS_REQUEST", payload: "" });
  } catch (err) {
    console.log(err);
  }
};

export const addToRequest = (val) => async (dispatch) => {
  try {
    dispatch({ type: "REQUEST_TOOL", payload: val });
  } catch (err) {
    console.log(err);
  }
};

export const getConsumeAllTools = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_ALL_TOOLS_CONSUME", payload: "" });
  } catch (err) {
    console.log(err);
  }
};

export const inputConsumeChange = (val) => async (dispatch) => {
  try {
    dispatch({ type: "CONSUME_INPUT_CHANGE", payload: val });
  } catch (err) {
    console.log(err);
  }
};

export const inputConsume = (val) => async (dispatch) => {
  try {
    dispatch({ type: "CONSUME_INPUT", payload: val });
  } catch (err) {
    console.log(err);
  }
};

export const inputConsumeDelete = () => async (dispatch) => {
  try {
    dispatch({ type: "CONSUME_INPUT_DELETE" });
  } catch (err) {
    console.log(err);
  }
};

export const consumeAdd = (val) => async (dispatch) => {
  try {
    dispatch({ type: "CONSUME_ADD", payload: val });
  } catch (err) {
    console.log(err);
  }
};

export const toolGetAllCategory = () => async (dispatch, getState) => {
  try {
    const location = await getState().user.location;
    const site = await getState().user.site;

    axiosInstance
      .post("procurement/procurement_dropdown/", {
        fields: ["tools_list", "category_list","sku_codes"],
        location: location,
        site: site,
      })
      .then((res) => {
        dispatch({ type: "GET_ALL_TOOLS_CATEGORY", payload: res.data });
      })
      .catch((err) => {
        console.log("Dashboard Error !", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const toolGetAllCategoryByAdminAction = (admin) => async (dispatch, getState) => {
  try {
    const location = await getState().user.location;
    const site = await getState().user.site;

    axiosInstance
      .post("procurement/procurement_dropdown/", {
        fields: ["tools_list", "category_list"],
        location: location,
        site: admin,
      })
      .then((res) => {
        dispatch({ type: "GET_ALL_TOOLS_CATEGORY", payload: res.data });
      })
      .catch((err) => {
        console.log("Dashboard Error !", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const procurementToolHistoryAction =
  (body, setLoading, setData, alert) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const site = await getState().user.site;
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `/procurement/get_tool_rate_history/`,
        { ...body, site, location }
      );
      if (res.data) {
        setData(res.data);
        setLoading(false);
        if (res.data.data.length === 0) {
          alert("No Data Found", { variant: "error" });
        }
      } else if (res.data.errorMsg) {
        setLoading(false);
        alert(res.data.errorMsg, { variant: "warning" });
      }
    } catch (err) {
      console.log(err);
    }
  };



  export const procurementMasterStockAction =
  (alert) => async (dispatch, getState) => {
    const location = await getState().user.location;
    const {category,name,sku_code,pg_no,on_page_data_client} = await getState().Procurement.masterStock
    // const site = await getState().user.site;
    try {
 
      const res = await axiosInstance.post(
        `/procurement/master_stock_table/`,
        { 
          category,
          name,
          sku_code,
          pg_no,
          on_page_data:on_page_data_client,
           location }
      );
      if (res.data) {
      
        if (res.data.data.length === 0) {
          alert("No Data Found", { variant: "error" });
        }else {
          dispatch({type:"GET_ALL_MASTER_STOCKS",payload:res.data})
        }
      } else if (res.data.errorMsg) {
     
        alert(res.data.errorMsg, { variant: "warning" });
      }
    } catch (err) {
      console.log(err);
    }
  };


  export const downloadMasterStockAction = (alert) => async (dispatch,getState) => {
    const location = await getState().user.location;
    try {
      const res = await axiosInstance.post(
        `/procurement/master_stock_report/`,
        {location},
        {
          responseType: "arraybuffer",
        }
      );
      if (res.data) {
        downloadSample(res.data, "Master_Stock_File");
        alert(" File Downloaded Succesfully", { variant: "success" });
      } else if (res.data.errorMsg) {
        alert(res.data.errorMsg, { variant: "error" });
      }
    } catch (err) {
      alert(err, {
        variant: "error",
      });
    }
  };
  